<template>
    <v-text-field
        :label="label"
        :background-color="background"
        :type="type"
        :error-messages="errorMessages"
        v-model="value"
        @input="onInput"
        :hide-details="details"
        :suffix="prefix"
        :prefix="prepend"
        :placeholder="placeholder"
        v-bind="$attrs"
        v-on="$listeners"
        solo
        :disabled="disabled"
    >
        <template #default></template>

        <template #prepend-inner>
            <slot name="prepend-inner"></slot>
        </template>

        <template #append>
            <slot name="append"></slot>
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: "TextField",
    model: {
        prop: "model",
        event: "input",
    },
    props: {
        label: {
            type: String,
            default: "",
        },
        errorMessages: {
            type: [String, Array],
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        model: {
            type: [String, Number],
            default: "",
        },
        prefix: {
            default: undefined, // обратная совместимость с vue
        },
        prepend: {
            default: undefined, // обратная совместимость с vue
        },
        placeholder: {
            default: "",
            type: String,
        },
        background: {
            default: `rgba(237,237,237, .1)`,
            type: String,
        },
        hideDetails: {
            default: true,
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
    },
    data: () => ({
        value: "",
    }),
    computed: {
        details() {
            return !(this.errorMessages.length > 0);
        },
    },
    watch: {
        model() {
            this.value = this.model;
        },
    },
    created() {
        this.value = this.model;
    },
    methods: {
        onInput() {
            this.$emit("input", this.value);
        },
    },
};
</script>
