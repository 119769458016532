const STATUS_NEW = 1;
const STATUS_CANCELED = 2;
const STATUS_COMPLETED = 3;
const STATUS_PAID = 4;

const PAYMENT_SYSTEM_BANK = "bank";
const PAYMENT_SYSTEM_BITSAFE = "bitsafe";
const PAYMENT_SYSTEM_INTERNATIONAL_WIRE_TRANSFER = "international_wire_transfer";
const PAYMENT_SYSTEM_CRYPTO_WALLET = "crypto_wallet";

export const statuses = {
    [STATUS_NEW]: "new",
    [STATUS_CANCELED]: "canceled",
    [STATUS_COMPLETED]: "completed",
    [STATUS_PAID]: "paid",
};

export const paymentSystems = {
    [PAYMENT_SYSTEM_BANK]: "EUR Bank Transfer (SEPA)",
    [PAYMENT_SYSTEM_BITSAFE]: "Yoursafe",
    [PAYMENT_SYSTEM_INTERNATIONAL_WIRE_TRANSFER]: "International Wire Transfer",
    [PAYMENT_SYSTEM_CRYPTO_WALLET]: "Crypto"
};
